<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :lastPage="nLastPage"
      @pageChange="fetchParametrosEquipes(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Equipes</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom v-can-access="902" @click="openModal('Cadastrar')" />
          <FilterEquipes
            @selectedFilters="fetchParametrosEquipes((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_indicador`]="{ item }">
        {{ item.id_indicador }} - {{ item.indicador }}
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>
      <template v-slot:[`item.prioridade_indic`]="{ item }">
        <v-icon v-if="item.prioridade_indic == '>'">mdi-greater-than</v-icon>
        <v-icon v-else>mdi-less-than</v-icon>
      </template>
      <template v-slot:[`item.mult_empresa`]="{ item }">
        <v-icon :color="status[item.mult_empresa].color">
          {{ status[item.mult_empresa].icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="statusAtivo[item.ativo].color"
          dark
          @click="statusItem(item)"
        >
          {{ statusAtivo[item.ativo].text }}
        </v-chip>
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        <span v-if="item.valor">
          {{ item.valor.replace(",", ".") | BrazilianCurrency }}
        </span>
      </template>
      <template v-slot:[`item.valor2`]="{ item }">
        <span v-if="item.valor">
          {{ item.valor2 | BrazilianCurrency }}
        </span>
      </template>
      <template v-slot:[`item.grupocomissao`]="{ item }">
        {{ item.desc_grupo_comissao }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!--<v-btn
          v-can-access="904"
          icon
          @click="openModal('Editar', item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="905"
          icon
          @click="deleteParametrosEquipes(item.id_funcao_param_equipe)"
          color="error"
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom
          :name="'edit'"
          v-can-access="904"
          @click="openModal('Editar', item)"
        />
        <IconBottom
          :name="'delete'"
          v-can-access="905"
          @click="deleteParametrosEquipes(item.id_funcao_param_equipe)"
        />
      </template>
    </BaseTable>
    <ModalCadastro
      :dialog="dialog"
      :labelBtn="labelBtn"
      :items="items"
      @updateFunctionList="fetchFuncao($event)"
      @closeModal="(dialog = false), fetchParametrosEquipes()"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import BaseSegmento from "@/components/shared/BaseSegmento";
import IconBottom from "@/components/shared/bottons/IconBottom";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalCadastro from "./ModalEquipes.vue";
import FilterEquipes from "./FilterEquipes.vue";
import comissao from "@/services/http/comissaoService";
import statusItem from "@/mixins/statusItem";
import statusAtivo from "@/mixins/statusAtivo";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  components: {
    BaseTable,
    RegisterBottom,
    BaseSegmento,
    FilterEquipes,
    ModalCadastro,
    IconBottom
  },

  mixins: [statusItem, statusAtivo, currencyMixin],

  data() {
    return {
      dialog: false,
      search: "",
      filters: {},
      labelBtn: "",
      items: {},
      loading: false,
      nLastPage: 0,
      headers: [
        { text: "Segmento", value: "id_band", align: "left" },
        { text: "Indicador", value: "id_indicador" },
        { text: "Priorid.", value: "prioridade_indic" },
        { text: "Tipo Comissão", value: "tipo_comissao" },
        { text: "Tipo Classe", value: "tipo_classe" },
        { text: "Fórmula Meta", value: "formula_meta" },
        { text: "Valor > M", value: "valor", align: "right" },
        { text: "Valor < M", value: "valor2", align: "right" },
        { text: "Status", value: "ativo", align: "center" },
        { text: "Grupo Comissão", value: "grupocomissao", align: "center" },
        { text: "Ações", value: "actions", align: "center" }
      ],
      info: []
    };
  },

  methods: {
    openModal(label, items = {}) {
      this.dialog = true;
      this.labelBtn = label;
      this.items = items;
    },
    async fetchParametrosEquipes(filters, nPage = 1) {
      let { data } = await comissao()
        .funcaoParametrosEquipes()
        .show({ page: nPage, ...filters });
      this.nLastPage = data.last_page;
      this.info = data.data;
    },

    async deleteParametrosEquipes(id) {
      try {
        await this.$swal.confirm(
          "Deseja deletar o parâmetro equipe?",
          "Essa ação não pode ser desfeita"
        );
        await comissao()
          .funcaoParametrosEquipes(id)
          .delete(null, { notification: true });
        await this.fetchParametrosEquipes();
      } catch (e) {
        console.log(e);
      }
    },

    async statusItem(item) {
      await this.$swal.confirm(
        `Atualmente o status é ${item.ativo == "S" ? "ativo" : "inativo"}`,
        `Alterar o status da meta?`
      );
      this.loading = true;
      item.ativo = item.ativo == "S" ? "N" : "S";
      comissao()
        .funcaoParametrosEquipes(item.id_funcao_param_equipe)
        .update(item, { notification: true });
    }
  },

  mounted() {
    this.fetchParametrosEquipes();
  }
};
</script>

<style></style>
