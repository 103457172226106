const statusItemMixin = {
  computed: {
    status() {
      return {
        S: {
          icon: "mdi-check",
          color: "green"
        },
        N: {
          icon: "mdi-close",
          color: "red"
        }
      };
    }
  }
};

export default statusItemMixin;
