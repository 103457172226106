export default {
  methods: {
    converteDate(date) {
      //in: 2020-06-30 00:00:00
      //out: 30/06/2020
      return date
        ? date
            .substring(0, 10)
            .split("-")
            .reverse()
            .join("-")
            .replaceAll("-", "/")
        : "";
    }
  }
};
