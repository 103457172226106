<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1020" persistent>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="toolbar-color">
            <span class="headline ml-2">
              Cadastro de Função Parâmetro - Equipe
            </span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <div>
            <v-form ref="form">
              <v-stepper
                style="box-shadow: none; background-color: transparent"
                v-model="selectedStep"
              >
                <v-stepper-content :elevation="0" flat step="1">
                  <v-container>
                    <v-row>
                      <!-- Empresa -->
                      <v-col cols="12" sm="3">
                        <BaseSelect
                          prepend-inner-icon="mdi-domain"
                          clearable
                          single-line
                          label="Segmento"
                          item-text="descricao"
                          item-value="id_band"
                          service="sistemaService.tipoBandeiras"
                          :disabled="locked"
                          :filters="{ id_band: '2,4' }"
                          v-model="form.id_empresa"
                          :rules="[rules.required]"
                          :isCompany="true"
                        />
                      </v-col>
                      <!-- Indicadores -->
                      <v-col cols="12" sm="5">
                        <BaseSelect
                          prepend-inner-icon="mdi-finance"
                          clearable
                          single-line
                          label="Indicador"
                          item-text="indicador"
                          item-value="id_indicador"
                          service="sistemaService.indicadores"
                          :disabled="locked"
                          v-model="form.id_indicador"
                          :filters="{ ativo: 'S' }"
                          :rules="[rules.required]"
                          :hasCode="true"
                        />
                      </v-col>
                      <!-- Função -->
                      <v-col cols="12" sm="4">
                        <BaseSelect
                          :rules="[rules.required]"
                          chips
                          label="Funções"
                          item-text="descricao"
                          item-value="id_funcao"
                          v-model="form.id_funcao"
                          service="sistemaService.funcoes"
                          :filters="{ id_band: form.id_band, ativo: 'S' }"
                          :disabled="locked"
                          clearable
                          single-line
                          prepend-inner-icon="mdi-account-network"
                        />
                      </v-col>

                      <!-- TipoComissao -->
                      <v-col cols="12" sm="4">
                        <BaseSelect
                          prepend-inner-icon="mdi-finance"
                          single-line
                          label="Tipo de comissão"
                          item-text="tipo_comissao"
                          item-value="id_tipo_comissao"
                          clearable
                          chips
                          :filters="{ status: 'S' }"
                          v-model="form.id_tipo_comissao"
                          service="comissaoService.tipoComissao"
                          :disabled="locked"
                          :rules="[rules.required]"
                        />
                      </v-col>

                      <!-- TipoClasse -->
                      <v-col cols="12" sm="4">
                        <BaseSelect
                          :rules="[rules.required]"
                          chips
                          label="Tipo de Classe"
                          item-text="tipo_classe"
                          item-value="id_tipo_classe"
                          service="comissaoService.tipoClasses"
                          :disabled="locked"
                          :filters="{ status: 'S' }"
                          v-model="form.id_tipo_classe"
                          clearable
                          prepend-inner-icon="mdi-chart-bubble"
                        />
                      </v-col>

                      <!-- Tipo Evento -->
                      <v-col cols="12" sm="4">
                        <BaseSelect
                          :rules="[rules.required]"
                          single-line
                          chips
                          label="Tipo Evento"
                          item-text="tipo_evento"
                          item-value="id_tipo_evento"
                          v-model="form.id_tipo_evento"
                          service="comissaoService.tipoEvento"
                          :disabled="locked"
                          :filters="{ id_tipo_evento: 'C' }"
                          clearable
                          prepend-inner-icon="mdi-calendar-text"
                          @change="fetchTipoFechamento()"
                        />
                      </v-col>
                      <!-- Tipo Dado -->
                      <v-col cols="12" sm="4">
                        <BaseSelect
                          :rules="[
                            rules.required,
                            rules.diffentThan(form.tipo_dado1, 'Tipo dado 2')
                          ]"
                          service="comissaoService.tipoDados"
                          :disabled="locked"
                          chips
                          label="Tipo Dado"
                          item-text="descricao"
                          item-value="id_tipo_dado"
                          v-model="form.tipo_dado"
                          :filters="{ status: 'S' }"
                          clearable
                          single-line
                          prepend-inner-icon="mdi-calendar-end"
                          @change="fetchTipoFechamento()"
                        />
                      </v-col>
                      <!-- Tipo Dado 2 -->
                      <v-col cols="12" sm="4">
                        <BaseSelect
                          :rules="[
                            rules.required,
                            rules.diffentThan(form.tipo_dado, 'Tipo dado')
                          ]"
                          service="comissaoService.tipoDados"
                          :disabled="locked"
                          chips
                          label="Tipo Dado 2"
                          item-text="descricao"
                          item-value="id_tipo_dado"
                          v-model="form.tipo_dado1"
                          :filters="{ status: 'S' }"
                          clearable
                          single-line
                          prepend-inner-icon="mdi-calendar-end"
                        />
                      </v-col>
                      <!-- Prioridade indicador -->
                      <v-col cols="12" sm="4">
                        <v-autocomplete
                          :rules="[rules.required]"
                          :value-comparator="comparator"
                          :items="prioridadeItems"
                          :disabled="locked"
                          chips
                          label="Prioridade do Indicador"
                          item-text="itemText"
                          item-value="itemValue"
                          v-model="form.prioridade_indic"
                          clearable
                          single-line
                          prepend-inner-icon="mdi-swap-vertical"
                        />
                      </v-col>
                      <!-- Tipo Fechamento -->
                      <v-col cols="12" sm="4">
                        <v-autocomplete
                          :disabled="locked"
                          :value-comparator="comparator"
                          :items="tipoFechamentoItems"
                          chips
                          label="Tipo Fechamento"
                          item-text="textTipoFechamento"
                          item-value="id_tipo_fechamento"
                          v-model="form.id_tipo_fechamento"
                          prepend-inner-icon="mdi-calendar-end"
                          clearable
                          single-line
                        />
                      </v-col>
                      <!-- Grupo comissão -->
                      <v-col cols="12" sm="4">
                        <BaseSelect
                          :rules="[rules.required]"
                          chips
                          label="Grupo Comissão"
                          item-text="descricao"
                          item-value="id_grupo_comissao"
                          v-model="form.id_grupo_comissao"
                          service="comissaoService.grupoComissao"
                          :disabled="locked"
                          prepend-inner-icon="mdi-account-group"
                          clearable
                          single-line
                        />
                      </v-col>

                      <!-- Valor -->
                      <v-col class="mt-2" cols="12" sm="2">
                        <vuetify-money
                          v-model="form.valor"
                          :rules="[rules.required]"
                          label="Valor"
                          valueWhenIsEmpty="0"
                          :options="optionsVuetifyMoney"
                        />
                      </v-col>

                      <!-- Valor 2 -->
                      <v-col class="mt-2" cols="12" sm="2">
                        <vuetify-money
                          :rules="[rules.required]"
                          v-model="form.valor2"
                          label="Valor 2"
                          valueWhenIsEmpty="0"
                          :options="optionsVuetifyMoney"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-container>
                    <v-row>
                      <!-- BaseCalculo -->
                      <v-col cols="12" sm="4">
                        <BaseSelect
                          :rules="[rules.required]"
                          chips
                          label="Base de Cálculo"
                          item-text="base_calculo"
                          item-value="id_base_calculo"
                          v-model="form.id_base_calculo"
                          service="comissaoService.baseCalculo"
                          :disabled="locked"
                          clearable
                          single-line
                          prepend-inner-icon="mdi-finance"
                        />
                      </v-col>

                      <!-- Base Comparativa -->
                      <v-col cols="12" sm="4">
                        <BaseSelect
                          :rules="[rules.required]"
                          chips
                          label="Base Comparativa"
                          item-text="base_comparativa"
                          item-value="id_base_comparativa"
                          v-model="form.id_base_comparativa"
                          service="comissaoService.baseComparativa"
                          :disabled="locked"
                          :filters="{ status: 'S' }"
                          clearable
                          prepend-inner-icon="mdi-arrow-left-right-bold"
                        />
                      </v-col>

                      <!-- tipoComparativo -->
                      <v-col cols="12" sm="4">
                        <BaseSelect
                          :rules="[rules.required]"
                          chips
                          label="Comparativo"
                          item-text="comparativo"
                          item-value="id_tipo_comparativo"
                          v-model="form.id_tipo_comparativo"
                          service="comissaoService.tipoComparativo"
                          :disabled="locked"
                          clearable
                          prepend-inner-icon="mdi-align-vertical-bottom"
                        />
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          :rules="[rules.required]"
                          label="Fórmula Indicador"
                          v-model="form.formula_indicador"
                          prepend-inner-icon="mdi-abacus"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          :rules="[rules.required]"
                          label="Fórmula Receita"
                          v-model="form.formula_receita"
                          prepend-inner-icon="mdi-abacus"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          :rules="[rules.required]"
                          label="Fórmula Meta"
                          v-model="form.formula_meta"
                          prepend-inner-icon="mdi-abacus"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          :rules="[rules.required, rules.maxValue(99)]"
                          type="number"
                          label="Percentual Indicador"
                          v-model="form.perc_indic"
                          prepend-inner-icon="mdi-percent"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          :rules="[rules.required, rules.maxValue(99)]"
                          type="number"
                          label="Percentual Receita"
                          v-model="form.perc_receita"
                          prepend-inner-icon="mdi-percent"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          :rules="[rules.required, rules.maxValue(99)]"
                          type="number"
                          label="Percentual Meta"
                          v-model="form.perc_meta"
                          prepend-inner-icon="mdi-percent"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-checkbox
                          false-value="N"
                          true-value="S"
                          v-model="form.mult_band"
                          label="Múltiplas Bandeiras"
                          :disabled="locked"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-checkbox
                          false-value="N"
                          true-value="S"
                          v-model="form.mult_empresa"
                          label="Múltiplas Empresas"
                          :disabled="locked"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-checkbox
                          false-value="N"
                          true-value="S"
                          v-model="form.replica"
                          label="Réplica"
                          :disabled="locked"
                        ></v-checkbox>
                      </v-col>
                    </v-row> </v-container
                ></v-stepper-content>
              </v-stepper>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="selectedStep == 1 ? close() : (selectedStep = 1)"
            >{{ selectedStep == 1 ? "Fechar" : "Anterior" }}</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="selectedStep == 1 ? (selectedStep = 2) : action(labelBtn)"
            >{{ selectedStep == 1 ? "Próximo" : labelBtn }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BaseSelect from "@/components/shared/BaseSelectService";
import converteDate from "@/mixins/tipoFechamento";
import comparator from "@/mixins/comparatorValue";
import comissao from "@/services/http/comissaoService";
import rules from "@/mixins/inputRules";

export default {
  name: "ModalCadastroFuncaoParametroDepartamento",

  components: {
    BaseSelect
  },

  mixins: [converteDate, comparator, rules],

  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    labelBtn: {
      type: String
    },
    items: {
      type: Object
    },
    empresasItems: {
      type: Array
    },
    indicadoresItems: {
      type: Array
    },
    tipoComissaoItems: {
      type: Array
    },
    funcaoItems: {
      type: Array
    },
    departamentosItems: {
      type: Array
    }
  },

  data() {
    return {
      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 11,
        precision: 2
      },
      locked: false,

      selectedStep: 1,
      form: { ativo: "S" },

      tipoFechamentoItems: [],
      tipoLancamentoItems: [
        {
          value: "A",
          text: "Automático"
        },
        {
          value: "M",
          text: "Manual"
        }
      ],

      grupoComissaoItems: [],
      baseCalculoItems: [],
      baseComparativaItems: [],
      tipoComparativoItems: [],
      prioridadeItems: [
        {
          itemText: "Maior",
          itemValue: ">"
        },
        {
          itemText: "Menor",
          itemValue: "<"
        }
      ]
    };
  },
  async mounted() {
    await this.fetchTipoFechamento();
  },

  methods: {
    action(labelBtn) {
      labelBtn == "Cadastrar" ? this.store() : this.update();
    },
    async fetchTipoFechamento() {
      let { data } = await comissao()
        .tipoFechamento()
        .show({
          per_page: -1,
          id_tipo_evento: this.form.tipo_evento,
          id_tipo_dado: this.form.tipo_dado
        });
      this.tipoFechamentoItems = data.data.map(item => {
        item["textTipoFechamento"] = `${this.converteDate(
          item.data_inicio
        )} - ${this.converteDate(item.data_final)}`;
        return item;
      });
    },

    updateFunctionList() {
      this.$emit("updateFunctionList", this.form?.id_empresa);
    },

    async store() {
      if (!this.$refs.form.validate()) return;
      await comissao()
        .funcaoParametrosEquipes()
        .store(this.form, { notification: true });
      await comissao()
        .parametrosEquipes()
        .store(this.form, { notification: false });
      this.close();
    },

    async update() {
      if (!this.$refs.form.validate()) return;
      Object.keys(this.form).forEach(key =>
        this.form[key] === null ? delete this.form[key] : {}
      );

      await comissao()
        .funcaoParametrosEquipes(this.form.id_funcao_param_equipe)
        .update(this.form, { notification: false });
      await comissao()
        .parametrosEquipes(this.form.id_equipe_param)
        .update(this.form, { notification: true });

      this.close();
    },

    close() {
      this.$emit("closeModal");
      this.selectedStep = 1;
      this.$refs.form.reset();
    }
  },
  watch: {
    items(val) {
      if (Object.keys(val).length === 0 && val.constructor === Object) return;
      this.form = Object.assign(this.form, val);
      this.form.valor = val.valor?.replace(",", ".") || 0;
    },
    ["form.id_tipo_classe"](val) {
      this.form["id_tipo_classe_totvs"] = val;
    },
    labelBtn(value) {
      this.locked = value == "Editar" ? true : false;
    }
  }
};
</script>

<style></style>
